<template>
  <div class="w-100" style="height: 100vh!important">
    <div class="vertical-center">
        <h1 class="white">
            404: Page Not Found
        </h1>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>